.spinner-holder {
  height: 2rem;
  width: 2rem;
  margin: auto;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -1rem;
}

.is-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1060;
}
