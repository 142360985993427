.modal {
  .close {
    display: inline-flex;
    color: #fff;
    transition: color 0.15s ease-in-out;
    background: $color_dark_4;
    border-radius: 0;
    padding: 8px 11px !important;
    font-size: 1rem;
    margin: -0.5rem -0.5em -1rem -1rem;
    text-shadow: none;
  }
}
.modal-content {
  border: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
  border-radius: 0%;
}
.modal-header,
.modal-body,
.modal-footer {
  border: 0;
  border-radius: 0%;
}
.modal-header {
  padding: 30px;
  background-color: $color_grey_2;
  @include media-breakpoint-down(xs) {
    padding: 20px;
  }
}
.modal-body {
  padding: 30px;

  @include media-breakpoint-down(xs) {
    padding: 20px 10px;
  }

  + .modal-footer {
    padding-top: 0;
  }
}
.modal-footer {
  padding: 30px;

  @include media-breakpoint-down(xs) {
    padding: 20px 10px;
  }

  > :not(:last-child) {
    margin-right: 5px;
  }
  > :not(:first-child) {
    margin-left: 5px;
  }
}
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
}
// Backdrop
.modal-backdrop {
  background-color: rgba($color_dark_2, 0.3);

  &.show {
    opacity: 1;
  }
}
