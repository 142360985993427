.component-files {
  .list-group-item-action.active {
    background-color: rgb(238, 242, 255);
  }

  .dropzone {
    min-height: unset;
  }

  &-dropzone {
    .dzu-dropzone {
      padding: 25px;
    }

    .dzu-dropzoneActive {
      background-color: #007bff;
    }

    .dzu-input {
      display: none;
    }

    .icon:hover {
      svg {
        color: #007bff !important;
        cursor: pointer;
      }
    }
  }

  .media {
    .media-img {
      background-color: #007bff;
      & > .icon-file {
        padding: 10px;
        position: unset;
      }
    }
  }

  .list-group {
    overflow: hidden;
  }
}
