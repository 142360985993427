.__react_component_tooltip {
    border-radius: 0;
    display: inline-block;
    font-size: 13px;
    left: -999em;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    top: -999em;
    visibility: hidden;
    z-index: 999;
  
    &.allow_hover,
    &.allow_click {
      pointer-events: auto;
    }
  
    &::before,
    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
    }
  
    &.show {
      opacity: 0.9;
      margin-top: 0;
      margin-left: 0;
      visibility: visible;
    }
  
    // Place
    &.place-top {
      &::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        bottom: -8px;
        left: 50%;
        margin-left: -10px;
      }
    }
  
    &.place-bottom {
      &::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        top: -8px;
        left: 50%;
        margin-left: -10px;
      }
    }
  
    &.place-left {
      &::before {
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        right: -8px;
        top: 50%;
        margin-top: -5px;
      }
    }
  
    &.place-right {
      &::before {
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        left: -8px;
        top: 50%;
        margin-top: -5px;
      }
    }
  
    // Wrapping
    .multi-line {
      display: block;
      padding: 2px 0;
      text-align: center;
    }
  }