#tooltabs-tabpane-map {
  .btn-link {
    padding: 0;
    margin: 0;
  }
}

.dropdown-menu.show {
  z-index: 10;
}

#drawing-manager-custom-button-0 {
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  margin-top: 5px;
  margin-left: -5px;
  margin-bottom: 22px;
  text-align: center;
  padding: 0px;
  &:hover {
    background-color: rgb(235, 235, 235);
  }
  @media (max-width: 992px) {
    width: 28px;
  }
  div {
    color: rgb(25, 25, 25);
    font-family: Roboto, Arial, sans-serif;
    font-size: 24px;
    line-height: 24px;
    padding-left: 5px;
    padding-right: 5px;
    @media (max-width: 992px) {
      font-size: 28px;
      line-height: 28px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

#drawing-manager-custom-button-1 {
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 0px;
  width: 22px;
  margin-bottom: 22px;
  text-align: center;
  padding: 0px;
  filter: grayscale(100%);
  &:hover {
    background-color: rgb(235, 235, 235);
  }
  @media (max-width: 992px) {
    width: 28px;
  }
  div {
    color: rgb(25, 25, 25);
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    line-height: 24px;
    padding-left: 2px;
    padding-right: 2px;
    @media (max-width: 992px) {
      line-height: 28px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

#drawing-manager-custom-button-2 {
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 0px;
  width: 22px;
  margin-bottom: 22px;
  text-align: center;
  padding: 0px;
  filter: grayscale(100%);
  &:hover {
    background-color: rgb(235, 235, 235);
  }
  @media (max-width: 992px) {
    width: 28px;
  }
  div {
    color: rgb(25, 25, 25);
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    line-height: 24px;
    padding-left: 2px;
    padding-right: 2px;
    @media (max-width: 992px) {
      line-height: 28px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

#drawing-manager-custom-button-3 {
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 0px;
  width: 56px;
  margin-bottom: 22px;
  text-align: center;
  padding: 0px;
  &:hover {
    background-color: rgb(235, 235, 235);
  }
  @media (max-width: 992px) {
    width: 56px;
  }
  div {
    color: rgb(25, 25, 25);
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    line-height: 24px;
    padding-left: 2px;
    padding-right: 2px;
    @media (max-width: 992px) {
      line-height: 28px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.side-with-shadow {
  box-shadow: 0px 0px 5px #bfbfbf;
}

.modal-shadow {
  .modal-content {
    box-shadow: 0px 0px 15px #afafaf;
  }
}

.gm-style-moc {
  display: none;
  pointer-events: none;
}

.designer-ui-button {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
}

.designer-ui-button:hover {
  background: rgb(235, 235, 235);
}
