.rui-sign {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > .row {
        min-height: 100vh;
    }

    .rui-sign-form {
        max-width: 380px;
        padding: 30px;
    }
    .rui-sign-form-cloud {
        max-width: 400px;
        padding: 40px;
        background-color: #fff;
        border-radius: .25rem;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
    }

    .rui-sign-or {
        display: flex;
        align-items: center;
        color: $color_text_1;

        &::before,
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            margin-bottom: -3px;
            border-bottom: 1px solid $color_separator;
        }
        &::before {
            margin-right: 20px;
        }
        &::after {
            margin-left: 20px;
        }
    }

    a.gray {
        color: $color_grey_5;

        &:hover {
            color: $color_text_2;
            text-decoration: none;
        }
    }
}
