.rui-router-transition {
  &-enter,
  &-exit {
    position: relative;
    > div {
      flex-grow: 1;
      flex-shrink: 0;
      min-height: auto;
    }
  }

  &-enter > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1;
  }

  &-enter-active > div {
    opacity: 1;
    transition: opacity 200ms 100ms ease-out, transform 200ms 100ms ease-out;
    transform: translateY(0);
    transform-origin: 50% 0;
  }

  &-exit > div {
    position: absolute;
    opacity: 1;
    transform: translateY(0);
    z-index: 2;
  }

  &-exit-active > div {
    max-height: calc(100vh - 20px);
    overflow: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in, transform 200ms ease-in;
    transform: translateY(20px);
    transform-origin: 50% 0;
  }
}
