.single-task-description {
  p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.btn-fake-link {
  border: 0;
  background-color: transparent;
  text-align: left;
  padding: 0;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.btn-icon-link:disabled {
  opacity: 0.3;
}

.bg-transparent {
  background-color: transparent;
}

.single-task-dropzone {
  text-align: left;
  min-height: auto;

  .dzu-input {
    display: none;
  }

  .dzu-inputLabel,
  .dzu-inputLabelWithFiles {
    margin: 0;
  }
}
