@import "../../assets/css/variables";
@import "../../assets/css/bootstrap/variables";

.cke_reset_all,
.cke_reset_all *,
.cke_reset_all a,
.cke_reset_all textarea {
  font-family: $font_body !important;
  font-size: 1.08rem !important;
}

a.cke_dialog_tab,
input.cke_dialog_ui_input_text,
input.cke_dialog_ui_input_password,
input.cke_dialog_ui_input_tel,
textarea.cke_dialog_ui_input_textarea,
select.cke_dialog_ui_input_select,
a.cke_dialog_ui_button {
  border-radius: 0 !important;
}

.cke_chrome {
  border-color: #e6ecf0 !important;
}

.cke_bottom {
  background-color: #fbfcfc !important;
  border-color: #e6ecf0 !important;
}

.cke_top {
  position: sticky !important;
  background-color: #fbfcfc !important;
  border-color: #e6ecf0 !important;
}

.rui-navbar-show {
  .cke_top {
    top: 70px !important;
  }
}

a.cke_dialog_ui_button {
  display: inline-block !important;
  font-weight: 400 !important;
  color: #212529 !important;
  text-align: center !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  padding: 0.075rem 0.75rem !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

a.cke_dialog_ui_button_ok {
  background-color: $green !important;
  color: #fff !important;
}

.cke_dialog_footer {
  padding: 10px !important;
  border-top: 1px solid #dee2e6 !important;
  background-color: #fff !important;
  text-align: left !important;
}
