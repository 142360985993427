@import "../../assets/css/variables";
@import "../../assets/css/yaybar";
@import "../../assets/css/rootui-parts/plugin-yay";

.yay-appbar {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 10%);

  button {
    width: calc(100% - 6px);
    height: 48px;
    display: block;
    margin: 12px auto;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    color: #333 !important;
    white-space: nowrap;
    overflow: hidden;

    &:disabled {
      opacity: 0.5;
    }

    svg {
      margin-bottom: 3px;
    }

    &.active {
      color: #007bff !important;
    }
  }
}

.yay-hide {
  .yay-appbar {
    display: none;
  }
}

.yay-hide-to-small {
  .yaybar-wrap {
    width: auto;
    & > ul {
      width: auto;
    }
  }
}

.yaybar.rui-yaybar .yaybar-wrap > ul {
  padding: 12px 0;
  & > li {
    white-space: nowrap;
    overflow: hidden;
  }
  & > li + .yay-label {
    margin: 0;
    padding: 10px 0 10px 15px;
  }
  & > li a {
    padding: 10px 0 10px 15px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.yay-wrap-menu {
  height: calc(100vh - 70px);
  overflow-y: visible;
}

.yay-sticky {
  position: sticky;
  top: 0;
  background: #f8f9fa;
  z-index: 2;
}
