$size: 34px !default;

.xdsoft_datetimepicker {
    padding: 28px 20px 25px 21px;
    font-family: $font_body;
    background-color: #fff;
    border: 1px solid $color_separator;
    border-radius: .25rem;
    box-shadow: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
    z-index: 10;

    .xdsoft_calendar {
        width: auto;

        thead th {
            padding-top: 11px;
            padding-bottom: 11px;
            font-weight: 600;
            color: $color_text_2;
            background-color: transparent;
            border: 0;
        }
    }
    .xdsoft_datepicker {
        width: auto;
        margin-top: -10px;
        margin-bottom: -10px;
        margin-left: 0;

        &.active + .xdsoft_timepicker {
            margin: -10px 0 0 9px;
        }
    }

    // Month Picker
    .xdsoft_monthpicker {
        position: relative;
        display: flex;
        align-items: center;

        .xdsoft_prev,
        .xdsoft_next,
        .xdsoft_today_button,
        .xdsoft_label {
            float: none;
        }
        .xdsoft_prev,
        .xdsoft_next,
        .xdsoft_today_button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: $size;
            min-width: $size;
            height: $size;
            color: $color_grey_5;
            text-indent: 0;
            background-image: none;
            border-radius: .2rem;
            opacity: 1;
            transition: color .1s ease-in-out;

            &:hover {
                color: $color_text_1;
            }
        }
        .xdsoft_next {
            margin-left: auto;

            > .rui-icon {
                transform: rotate(180deg);
            }
        }
        .xdsoft_today_button {
            margin-right: auto;
            margin-left: 0;
        }
        .xdsoft_label {
            padding: 0;
            font-weight: 400;
            color: $color_text_1;

            > i {
                display: none;
            }
            &:hover > span {
                text-decoration: none;
            }
        }
        .xdsoft_month {
            width: auto;
        }
        .xdsoft_year {
            width: auto;
            margin-left: 8px;
        }
    }

    // Select Dropdown
    .xdsoft_label > .xdsoft_select {
        top: -5px;
        max-height: 257px;
        padding: 0 5px;
        border: 1px solid $color_separator;
        border-radius: .25rem;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .03);

        &.xdsoft_yearselect {
            right: auto;
            left: 100%;
            margin-left: 10px;
        }
        &.xdsoft_monthselect {
            right: 100%;
            margin-right: 10px;
        }

        > div {
            padding-top: 3px;
            padding-bottom: 3px;

            > .xdsoft_option {
                position: relative;
                padding: 4px 8px;
                font-size: 12px;
                color: $color_secondary;
                transition: color .1s ease-in-out;

                &::before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    right: 0;
                    bottom: 2px;
                    left: 0;
                    border-radius: .2rem;
                    transition: background-color .1s ease-in-out;
                    z-index: -1;
                }

                &:hover {
                    // stylelint-disable-next-line
                    color: $color_text_1 !important;
                    // stylelint-disable-next-line
                    background-color: transparent !important;

                    &::before {
                        background-color: $color_grey_2;
                    }
                }
                &.xdsoft_current {
                    font-weight: 400;
                    // stylelint-disable-next-line
                    color: #fff !important;
                    // stylelint-disable-next-line
                    background-color: transparent !important;
                    box-shadow: none;

                    &::before {
                        background-color: rgba($color_brand, .8);
                    }
                }
            }
        }

        .xdsoft_scrollbar {
            width: 4px;
            opacity: 0;
            transition: opacity .1s ease-in-out;
        }
        .xdsoft_scroller {
            padding: 0;
            // stylelint-disable-next-line
            background-color: $color_grey_3 !important;

            &:hover {
                // stylelint-disable-next-line
                background-color: $color_grey_3 !important;
            }
        }
        &:hover .xdsoft_scrollbar {
            opacity: 1;
        }
    }

    // Time Picker
    .xdsoft_timepicker {
        position: relative;
        width: auto;
        padding-top: $size;
        margin-bottom: 5px;
        margin-left: 0;

        &.active {
            margin-top: -10px;
            margin-bottom: -10px;
        }

        .xdsoft_time_box {
            height: 228px;
            margin-top: 2px;
            border: 0;

            &:hover .xdsoft_scrollbar {
                opacity: 1;
            }
            .xdsoft_scrollbar {
                width: 4px;
                opacity: 0;
                transition: opacity .1s ease-in-out;
            }
            .xdsoft_scroller {
                padding: 0;
                // stylelint-disable-next-line
                background-color: $color_grey_3 !important;

                &:hover {
                    // stylelint-disable-next-line
                    background-color: $color_grey_3 !important;
                }
            }

            > div > div {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: $size + 4px;
                padding: 0 10px;
                color: $color_secondary;
                background-color: transparent;
                border: 0;
                border-radius: .2rem;
                transition: background-color .1s ease-in-out, color .1s ease-in-out;

                &::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: 0;
                    bottom: 4px;
                    left: 0;
                    border-radius: .2rem;
                    transition: background-color .1s ease-in-out;
                    z-index: -1;
                }

                &:hover {
                    // stylelint-disable-next-line
                    color: $color_text_1 !important;
                    // stylelint-disable-next-line
                    background-color: transparent !important;

                    &::before {
                        background-color: $color_grey_2;
                    }
                }

                &.xdsoft_current {
                    font-weight: 400;
                    // stylelint-disable-next-line
                    color: #fff !important;
                    // stylelint-disable-next-line
                    background-color: transparent !important;
                    box-shadow: none;

                    &::before {
                        background-color: rgba($color_brand, .8);
                    }
                }
            }
        }
        .xdsoft_prev,
        .xdsoft_next {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: 0;
            width: 25px;
            height: $size;
            margin: 0;
            color: $color_grey_5;
            text-indent: 0;
            background-image: none;
            opacity: 1;
            transition: color .1s ease-in-out;

            &:hover {
                color: $color_text_1;
            }
        }
        .xdsoft_prev {
            left: 0;

            > .rui-icon {
                transform: rotate(90deg);
            }
        }
        .xdsoft_next {
            left: 25px;

            > .rui-icon {
                transform: rotate(-90deg);
            }
        }
    }

    // Calendar
    .xdsoft_calendar td {
        padding: 4px;
        text-align: center;
        background-color: transparent;
        border: 0;

        &.xdsoft_other_month {
            opacity: 1;

            > div {
                color: $color_grey_5;

                &:hover {
                    // stylelint-disable-next-line
                    color: $color_grey_6 !important;
                }
            }
        }

        > div {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: $size - 2px;
            height: $size - 2px;
            padding-right: 0;
            color: $color_secondary;
            border-radius: 100%;
            transition: color .1s ease-in-out, background-color .1s ease-in-out;
        }
        &:hover {
            // stylelint-disable-next-line
            color: $color_text_1 !important;
            // stylelint-disable-next-line
            background-color: transparent !important;

            > div {
                // stylelint-disable-next-line
                color: $color_text_1 !important;
                // stylelint-disable-next-line
                background-color: $color_grey_2 !important;
            }
        }
        &.xdsoft_current {
            // stylelint-disable-next-line
            background-color: transparent !important;
            box-shadow: none;

            > div {
                font-weight: 400;
                // stylelint-disable-next-line
                color: #fff !important;
                // stylelint-disable-next-line
                background-color: rgba($color_brand, .8) !important;
            }
            &.xdsoft_today {
                // stylelint-disable-next-line
                background-color: transparent !important;
                box-shadow: none;

                > div {
                    font-weight: 400;
                    // stylelint-disable-next-line
                    color: #fff !important;
                    // stylelint-disable-next-line
                    background-color: rgba($color_brand, .8) !important;
                }
            }
        }
    }
}

// Wrap (window < 576)
.rui-datetimepicker-wrap {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    z-index: 1010;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color_dark_2, .9);
    }

    &.show {
        visibility: visible;
        opacity: 1;
    }

    > .xdsoft_datetimepicker {
        // stylelint-disable-next-line
        position: static !important;
        max-height: 100%;
        border-color: transparent;
    }
}
