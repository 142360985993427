@import "../../assets/css/variables";
@import "../../assets/css/rootui-parts/sign";

.rui-sign-form {
  .svg-inline--fa.rui-icon {
    width: 1em;
    min-width: 1em;
    height: 1em;
  }
}

.rui-router-transition-exit-active > .rui-sign {
  min-height: calc(100vh - 20px);
  padding-top: 20px;
}
