@import "../../assets/css/variables";

.dropzone {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  background: transparent;
  box-sizing: border-box;
  min-height: 150px;
  position: relative;
  i {
    font-size: 50px;
    color: $color_grey_border;
  }
  .dzu-input {
    border: 1px solid $color_grey_border;
    padding: 6px;
    margin-left: 15px;
  }
  .dzu-previewStatusContainer,
  .dzu-submitButtonContainer {
    display: none;
  }
  .dzu-previewImage {
    width: 75px;
  }
}

.dropzone-left-inline {
  text-align: left;
  background: transparent;
  box-sizing: border-box;
  position: relative;
  i {
    font-size: 50px;
    color: $color_separator_dark;
  }
  .dzu-inputLabel,
  .dzu-inputLabelWithFiles {
    width: 100%;
  }
  .dzu-input {
    width: 100%;
    border: 1px solid $color_separator_dark;
    line-height: 30px;
  }
  .dzu-previewStatusContainer,
  .dzu-submitButtonContainer {
    display: none;
  }
  .dzu-previewImage {
    width: 75px;
  }
}
