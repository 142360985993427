.nav-profile-img {
  img {
    width: 36px;
    height: 36px;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    background-color: #fbfcfc;
    border: 1px solid #ced4da;
  }
  .img {
    width: 36px;
    height: 36px;
    line-height: 34px;
    border-radius: 50%;
    font-size: 16px;
    background-color: #fbfcfc;
    border: 1px solid #ced4da;
    display: block;
  }
}

.navbar-milky {
  background-color: rgba(245, 245, 245, 0.5);
  backdrop-filter: blur(12px);
}

.rui-navbar-mobile {
  background-color: transparent;
}

.btn-custom-language {
  &::after {
    opacity: 0;
    background-color: transparent;
  }
  &:hover {
    &::after {
      opacity: 0;
    }
  }

  img {
    border-radius: 50%;
    border: 1px solid #333;
    background-color: #fff;
  }
}

@media screen and (max-width: 1200px) {
  .rui-navbar-brand {
    opacity: 0;
  }
  .rui-yaybar-toggle-parent {
    display: none !important;
  }
}

.rui-navbar-logo {
  margin-left: -5px;
}
