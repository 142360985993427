@import "../../assets/css/variables";

#dashboard-projects {
  margin: -10px -5px;
  .rt-tr {
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  #dashboard-projects {
    margin: -30px -15px;
  }
}

.ReactTable .rt-tbody {
  overflow: unset !important;
}

.rt-resizable-header:first-of-type,
.-filters .rt-th:first-of-type,
.rt-tr-group .-even .rt-td:first-of-type {
  position: sticky !important;
  left: 0;
  background-color: #fff !important;
  z-index: 99;
  align-self: flex-start;
}

.rt-tr-group .-odd .rt-td:first-of-type {
  position: sticky !important;
  left: 0;
  background-color: #f7f7f7 !important;
  z-index: 99;
  align-self: flex-start;
}
