#goodjob {
  padding: 25px 0;

  .box {
    color: #999;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 50px;
    font-weight: bold;
    display: block;
    text-align: center;
  }

  .flip {
    height: 50px;
    overflow: hidden;
  }

  .flip > div > div {
    color: #fff;
    padding: 0;
    height: 45px;
    margin-bottom: 45px;
    display: inline-block;
  }

  .flip div:first-child {
    @media only screen and (min-width: 620px) {
      animation: show 5s linear infinite;
    }
  }

  .flip .one {
    background: #42c58a;
  }
  .flip .two {
    background: #4ec7f3;
  }
  .flip .three {
    background: #dc143c;
  }

  @keyframes show {
    0% {
      margin-top: -270px;
    }
    5% {
      margin-top: -180px;
    }
    33% {
      margin-top: -180px;
    }
    38% {
      margin-top: -90px;
    }
    66% {
      margin-top: -90px;
    }
    71% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -270px;
    }
  }
}
