.rui-quill {
  .rui-quill-toolbar {
    display: none;
    padding: 24px 21px;
    font-family: $font_title;
    border-color: $color_grey_border;
    border-bottom: 0;
    border-radius: 0.25rem 0.25rem 0 0;

    &.ql-toolbar {
      display: block;
    }
    .ql-stroke {
      stroke: $color_text_1;
      transition: stroke 0.15s ease-in-out;
    }
    .ql-fill {
      fill: $color_text_1;
      transition: fill 0.15s ease-in-out;
    }
    button {
      &:hover {
        .ql-stroke {
          stroke: $color_brand;
        }
        .ql-fill {
          fill: $color_brand;
        }
      }
      &.ql-active {
        .ql-stroke {
          stroke: $color_brand;
        }
        .ql-fill {
          fill: $color_brand;
        }
      }
    }
    // Select
    .ql-picker {
      width: auto;
      padding-right: 25px;

      svg.rui-icon {
        width: 16px;
        margin-top: -8px;
        stroke: $color_text_1;
        transition: stroke 0.15s ease-in-out;
      }

      .ql-picker-label {
        display: inline-flex;
        align-items: center;
        color: $color_text_1;
        border: 0;
        transition: color 0.15s ease-in-out;

        > svg {
          display: none;
        }
        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          right: 0;
          bottom: 0;
          width: 30px;
          margin-right: -30px;
          z-index: 1;
        }

        &:focus {
          outline: none;
        }
        &:hover {
          color: $color_text_2;
        }
      }
      .ql-picker-options {
        padding: 15px 19px;
        margin-top: 3px;
        margin-left: -13px;
        border: 1px solid $color_grey_2;
        border-radius: 0.25rem;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);

        .ql-picker-item {
          padding: 2px 0;
          font-weight: 400;
          color: $color_grey_6;
          transition: color 0.15s ease-in-out;

          &[data-value="1"] {
            margin-top: -5px;

            &::before {
              font-size: 1.715rem;
            }
          }
          &[data-value="2"] {
            margin-top: -1px;

            &::before {
              font-size: 1.43rem;
            }
          }
          &[data-value="3"] {
            margin-top: 2px;

            &::before {
              font-size: 1.29rem;
            }
          }
          &:last-child {
            margin-top: 3px;
          }

          &:hover {
            color: $color_text_2;
          }
          &:focus {
            outline: none;
          }
        }
      }
      &:focus {
        outline: none;
      }
      &.ql-expanded {
        svg.rui-icon {
          stroke: $color_text_2;
        }
        .ql-picker-label {
          color: $color_text_2;
        }
        .ql-picker-options {
          border-color: $color_grey_border;
        }
      }
    }
  }
  .rui-quill-editor {
    min-height: 150px;
    border-radius: 0 0 0.25rem 0.25rem;

    .ql-editor {
      min-height: 150px;
      padding: 25px;
      font-size: 1.1rem;
      line-height: 1.5;
      border: 1px solid $color_brand_graphite;

      h1 {
        font-size: 1.846rem;
      }
      h2 {
        font-size: 1.538rem;
      }
      h3 {
        font-size: 1.385rem;
      }
      h4 {
        font-size: 1.231rem;
      }
      h5 {
        font-size: 1.077rem;
      }
      h6 {
        font-size: 0.923rem;
      }
    }
  }
}

.rui-quill .ql-container {
  border-color: $color_grey_border;
  border-top: 0;
}
