.mapper-legend {
  position: absolute;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #fff;
  padding: 10px;
  margin-top: 60px;
  margin-left: 60px;
  max-width: 240px;
  line-height: 1;
  text-align: justify;

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }
}
