.rui-profile-img {
  display: block;
  max-width: 128px;
  width: 100%;
  overflow: hidden;
  margin: 15px auto;
  text-align: center;
  small {
    font-size: 11px;
    color: #333;
  }
  img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #ced4da;
    background-color: #fbfcfc;
  }
  .img {
    width: 128px;
    height: 128px;
    line-height: 122px;
    letter-spacing: 3px;
    border-radius: 50%;
    font-size: 48px;
    background-color: #fbfcfc;
    border: 1px solid #ced4da;
    cursor: pointer;
  }
  .dzu-input {
    display: none;
  }
}
