.rui-selectize {
    // Base
    .rui-selectize-element {
        display: flex;
        padding: 0;
        border: 0;

        .selectize-input {
            min-height: 36px;
            padding: 7px 30px 7px 17px;
            background-color: lighten($color_grey_1, 1);
            border: 1px solid $color_separator;
            border-radius: .25rem;
            box-shadow: 0 0 0 0 rgba($color_brand, 0);
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

            &:focus,
            &.focus {
                background-color: lighten($color_grey_1, 1);
                border-color: rgba($color_brand, .6);
                box-shadow: 0 0 0 .2rem rgba($color_brand, .2);
            }
        }
        .selectize-dropdown {
            height: auto;
            padding: 9px 5px 10px;
            margin-top: 10px;
            border: 1px solid $color_separator;
            border-radius: .25rem;
            box-shadow: 0 3px 10px rgba(0, 0, 0, .03);

            .option {
                padding: 6px 14px;
                color: $color_secondary;
                cursor: pointer;
                transition: color .1s ease-in-out;

                &:hover {
                    color: $color_text_1;
                }
            }
            .create {
                display: flex;
                align-items: center;
                padding: 6px 14px;
                color: $color_secondary;

                strong {
                    margin-left: 5px;
                    font-weight: 400;
                    color: $color_text_2;
                }
            }
            .highlight {
                background-color: $color_grey_2;
            }
            .active {
                color: $color_text_2;
                background-color: transparent;
            }
        }
    }
    // Select
    .rui-selectize-select {
        .selectize-input,
        &.single .selectize-input {
            &::after {
                border-color: $color_grey_5 transparent transparent transparent;
                border-style: solid;
                border-width: 5px 5px 0 5px;
                transition: transform .15s ease-in-out;
                transform: rotate(0);
            }

            &:focus,
            &.focus {
                &::before {
                    display: none;
                }
                &::after {
                    margin-top: -3px;
                    border-color: $color_text_2 transparent transparent transparent;
                    border-style: solid;
                    border-width: 5px 5px 0 5px;
                    transform: rotate(180deg);
                }
            }
        }
        // Custom Select-Icon
        .rui-selectize-select-icon.selectize-input {
            .rui-selectize-select-icon {
                position: absolute;
                top: 50%;
                right: 11px;
                margin-top: -8px;
                stroke: $color_grey_5;
                transition: stroke .15s ease-in-out, transform .15s ease-in-out;
                transform: rotate(0);
            }
            &:focus .rui-selectize-select-icon,
            &.focus .rui-selectize-select-icon {
                stroke: $color_text_2;
                transform: rotate(180deg);
            }
            &::after {
                display: none;
            }
        }
    }
    // Input
    .rui-selectize-input {
        .selectize-input {
            &.has-items {
                padding: 5px 15px;
            }

            &::after {
                display: none;
            }

            .item {
                padding: 1px 6px 3px;
                margin: 2px 5px 2px 0;
                color: $color_secondary;
                background-color: $color_grey_2;
                border-radius: .2rem;
                transition: background-color .1s ease-in-out, color .1s ease-in-out;

                &:hover {
                    background-color: darken($color_grey_2, 3);
                }
                &.active {
                    color: $color_text_2;
                    background-color: darken($color_grey_2, 3);
                }
            }
        }
        .selectize-dropdown {
            .create strong {
                display: inline-flex;
                padding: 1px 6px 3px;
                background-color: $color_grey_1;
                border-radius: .2rem;
            }
        }
    }
    // Icon
    .rui-selectize-icon {
        .selectize-input {
            display: flex;
            align-items: center;

            &.input-active {
                display: flex;
            }
            &.dropdown-active > .option > .rui-icon {
                stroke: $color_text_2;
            }
            > .option {
                display: inline-flex;
                align-items: center;

                > .rui-icon {
                    margin-right: 10px;
                    stroke: $color_grey_5;
                    transition: stroke .15s ease-in-out;
                }
            }
        }
        .selectize-dropdown .option {
            display: flex;
            align-items: center;
            padding: 7px 14px;

            &.active > .rui-icon {
                stroke: $color_text_2;
            }
            > .rui-icon {
                margin-right: 10px;
                stroke: $color_grey_5;
                transition: stroke .15s ease-in-out;
            }
        }
    }
    // Github
    .rui-selectize-github {
        .selectize-dropdown {
            padding: 0;

            > .selectize-dropdown-content {
                width: 100%;

                > div {
                    width: 100%;
                    padding: 15px 20px 13px;
                    background-color: #fff;
                    transition: background-color .15s ease-in-out;

                    .title {
                        display: flex;
                        align-items: center;

                        .by {
                            display: inline-block;
                            margin-left: 15px;
                            font-size: 10px;
                            color: $color_grey_6;

                            &::before {
                                content: "by ";
                            }
                        }
                    }
                    .description {
                        display: block;
                        margin-top: 12px;
                        color: $color_grey_6;
                    }
                    .meta {
                        display: flex;
                        padding: 0;
                        margin: 9px 0 0;
                        font-size: 10px;
                        color: $color_secondary;

                        > li {
                            display: inline-block;
                            padding: 1px 10px 1px 0;
                            list-style: none;
                        }
                    }
                    &.selected,
                    &.active {
                        background-color: $color_grey_1;
                    }
                }
            }
        }
    }
}
