@keyframes run {
  0% {
    left: -100vw;
    bottom: -100vh;
    transform: translateX(100vw) translateY(100vh) rotate(35deg);
  }
  100% {
    left: 100vw;
    bottom: 100vh;
    transform: translateX(-100vw) translateY(-100vh) rotate(35deg);
  }
}
.unicorn-vernissage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1150;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  pointer-events: none;

  img {
    max-height: 200px;
    animation: linear infinite;
    animation-name: run;
    animation-duration: 7.5s;
  }
}
