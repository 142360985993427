.hljs {
    width: 100%;
    padding: 0;
    margin-top: -3px;
    margin-bottom: -3px;
    color: $color_text_2;
    background: none;
}
.hljs-string {
    color: $color_danger;
}
.hljs-literal {
    color: $color_success;
}
.hljs-number {
    color: $color_primary;
}
