@import "../../assets/css/variables";

.moveable-container {
  position: relative;
  min-height: 500px;
  height: 100%;
  width: 100%;
}

.moveable {
  position: relative;
  text-align: center;
  margin: 0px auto;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid black;
}

.moveable:after {
  content: attr(data-desc);
  position: absolute;
  left: -64px;
  top: calc(50% - 12px);
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  clear: both;
}

.moveable span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.moveable-label {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 5px;
  background: #333;
  z-index: 3001;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: none;
  transform: translate(-100%, -100%);
}

.pv {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.55);
  border: 1px solid black;
}

.roof {
  background-color: #eee;
}

.roof-gradient {
  background-image: repeating-linear-gradient(45deg, #fff, #fff 2px, #eee 2px, #eee 8px);
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-visible {
  overflow: visible;
}

.comments {
  p {
    margin: 0;
    padding: 0;
  }
}

.modal-offer {
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }
}

.strong {
  font-weight: bold !important;
}

.modal-fs {
  width: 95vw;
  max-width: none;

  .modal-body {
    height: 82vh;
    max-height: none;
  }
}

.offer-inputs {
  .form-label {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.offer-financing {
  .form-label {
    max-width: 100%;
  }
}
