.nav {
  .dropdown-toggle::after {
    display: none;
  }
}

.card {
  border-radius: 0;
}

.fpl {
  position: fixed;
  z-index: 999;
  width: 150px;
  height: 94px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn-link {
  color: #007bff !important;
}

.btn-link.btn-link-danger {
  color: #dc3545 !important;
}

.btn-no-upper {
  text-transform: none !important;
}

.btn-inline {
  padding: 0;
  display: inline;
  text-transform: none;
  color: #007bff;
  border: 0;
  background-color: transparent;
}

label,
.form-control,
.table th,
.table td {
  color: $color_text_1;
}

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.nav-tabs .nav-link {
  white-space: nowrap;
  margin-left: 0;
  margin-right: 0;
}

.nav-right-side {
  .nav-link {
    border-left: 2px solid transparent;
    padding-left: 5px;
    &.active {
      color: #007bff;
      border-left-color: rgba(0, 123, 255, 0.8);
      background: rgba(0, 123, 255, 0.1);
    }
  }
}

.required {
  .form-label:after {
    content: " *";
    color: red;
  }
}

label.required {
  &:after {
    content: " *";
    color: red;
  }
}

h2:not(.swal2-title),
.h2 {
  background: rgba(245, 245, 245, 0.5);
  padding: 20px;
  border: 0;
  @include media-breakpoint-down(md) {
    font-size: 1.285rem;
    padding: 5px 8px;
    background: transparent;
    border-bottom: 1px solid #e6ecf0;
    margin: 0 0 1rem;
  }
}

.yaybar.rui-yaybar .os-scrollbar {
  background-color: transparent !important;
}

@include media-breakpoint-up(xl) {
  * {
    &::-webkit-scrollbar {
      width: 18px;
      height: 18px;
    }
    &::-webkit-scrollbar-thumb {
      border: 6px solid transparent;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb:vertical {
      min-height: 30px;
    }
    &::-webkit-scrollbar-thumb:horizontal {
      min-width: 30px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
      background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
      background-clip: padding-box;
      border: 4px solid transparent;
    }
    &.smallscroll {
      &::-webkit-scrollbar-thumb:hover {
        border: 6px solid transparent;
      }
    }
  }
}

body {
  :focus {
    outline: #007bff auto 2px !important;
  }
  &.using-mouse {
    :focus {
      outline: none !important;
    }
  }
}

.rui-page-content {
  min-height: 80vh;
}

.no-min-height {
  min-height: unset !important;
}

.no-scroll {
  overflow: hidden;
}

.-mx-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.alert {
  text-overflow: ellipsis;
  overflow: hidden;
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.modal-title {
  max-width: 90%;
}

.animate-appear {
  opacity: 0;
  animation: animateAppear 2s infinite;
}

@keyframes animateAppear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.33;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tooltip-multiline {
  white-space: pre-wrap;
}

.div-as-input {
  min-height: 36px;
  padding: 5px 10px;
  background-color: #fbfcfc;
  border: 1px solid #9e9e9e;
}

.border-5 {
  border-width: 5px !important;
}

.border-blue {
  border-color: rgb(91, 155, 213) !important;
}

.p-infobox {
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.disable-select {
  user-select: none;
}

.force-select {
  user-select: all;
}

.rui-timeline {
  .rui-timeline-line-blue {
    background-color: rgba(0, 123, 255, 0.6) !important;
    width: 6px !important;
    margin-left: -3px !important;
    transition: height 0.15s ease-in-out !important;
    bottom: unset;
  }
}

.tl-operations-project {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  min-height: 85px;
  align-items: baseline;

  & > .rui-timeline-content {
    border: 0 !important;
    margin-top: 5px !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
  }

  & > .rui-timeline-icon {
    top: 20px !important;
    transition: all 0.15s ease-in-out !important;
  }

  & > .rui-timeline-date {
    margin-top: 20px !important;
    padding: 0px !important;
  }

  & > .tl-active {
    background-color: #007bff !important;
    border-color: #007bff !important;
    color: #fff !important;
  }
}

div[data-indx] {
  & {
    position: relative;
  }
  &::before {
    content: attr(data-indx);
    position: absolute;
    z-index: 9999;
    width: 30px;
    height: 30px;
    padding: 5px;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: orange;
    color: black;
    margin-left: -10px;
    border-radius: 50%;
    display: block;
    opacity: 0.8;
  }
}

.rui-current-page-is-mapper {
  overflow: hidden;
}

.rui-full-page-content {
  .rui-page-content {
    padding: 0;
    .container-fluid {
      margin: 0;
      padding: 0;
    }
  }
}

@media print {
  table {
    page-break-inside: avoid;
  }

  .rui-navbar,
  .rui-footer,
  .rui-page-title,
  .yaybar {
    display: none !important;
  }

  .rui-page {
    margin: 0 !important;
  }

  .rui-page-content {
    padding: 10px;
  }

  .rui-navbar-top ~ .rui-page.rui-page-react > div {
    min-height: 100vh !important;
  }

  .scheduler .wrapper {
    overflow: visible;
  }
}

@keyframes animate-append {
  0% {
    /*transform: scale(0);*/
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    /*transform: scale(1);*/
    opacity: 1;
  }
}

@keyframes animate-scale-append {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animate-fade-append {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group,
.animate-append {
  animation: animate-append 0.15s ease-in-out;
}

.animate-fade-append {
  animation: animate-fade-append 0.3s ease-in-out;
}

.__react_component_tooltip {
  max-width: 300px;
  overflow-wrap: break-word;
}
