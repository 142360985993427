.rui-current-page-is-scheduler {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .sm-mt-10 {
    margin-top: 10px;
  }
}

.scheduler {
  margin: auto;
  width: 100%;

  table {
    border: none;
    border-collapse: initial !important;
    border-spacing: 0;
    position: relative;
    border-bottom: 1px solid #e6ecf0;
    border-right: 1px solid #e6ecf0;
    font-size: 13px;

    th {
      vertical-align: middle !important;
      display: table-cell!important;
    }

    th,
    td {
      padding: 2px !important;
      border-top: 1px solid #e6ecf0;
      border-left: 1px solid #e6ecf0;
    }
  }

  button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    border: 0;
    font-weight: 400;
    color: #333;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .monospace {
    font-family: "Courier New", Courier, monospace !important;
  }

  .wrapper {
    position: relative;
    overflow: auto;
    white-space: nowrap;
  }

  .sticky-col {
    @media screen and (min-width: 1200px) {
      position: sticky;
    }
    background-color: white;
    z-index: 2;
    button {
      color: #007bff;
      padding: 0;
    }
  }

  .first-col {
    left: 0px;
    vertical-align: middle;
    display: table-cell;
    overflow: visible;
    width: 290px;
    max-width: 440px;
    min-height: 30px;
  }

  .second-col {
    overflow: visible;
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    min-height: 30px;
  }

  .third-col {
    overflow: hidden;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    background: linear-gradient(to left, #e6ecf0 0%, white 10%);
    min-height: 30px;
  }

  .mode-advanced {
    min-height: 110px;
  }

  .drop-it-like-its-hot {
    background-color: rgba(100, 255, 100, 0.5) !important;
  }

  .no-events {
    pointer-events: none;
  }

  .schedule-view {
    &-days {
      .sat {
        background-color: rgba(255, 193, 7, 0.1) !important;
      }

      .sun {
        background-color: rgba(245, 66, 54, 0.1) !important;
      }
    }
    &-weeks {
      th:not(.sticky-col) {
        min-width: 100px;
        &:last-of-type {
          width: 100%;
        }
      }
    }
    &-months {
      th:not(.sticky-col) {
        min-width: 100px;
        &:last-of-type {
          width: 100%;
        }
      }
    }
  }

  .droptask:hover {
    background-color: rgba(100, 255, 100, 0.2) !important;
    cursor: pointer;
  }

  .month-1,
  .month-3,
  .month-5,
  .month-7,
  .month-9,
  .month-11 {
    background-color: rgba(190, 190, 190, 0.1);
  }

  .today {
    position: relative;
    &:before {
      content: "";
      width: 2px;
      height: 100%;
      display: block;
      left: 50%;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      background: linear-gradient(#ff0000, #ff0000) no-repeat center/2px 100%;
    }
  }

  .task {
    position: absolute;
    background-color: rgba(100, 255, 100, 0.7);
    z-index: 1;
    padding: 0 2px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .br {
      background-color: rgba(50, 50, 50, 0.8);
      border: 1px solid white;
      width: 12px;
      cursor: ew-resize;
      line-height: 20px;
      margin-left: 6px;
    }
  }
}
