@import "../../assets/css/variables";

@-webkit-keyframes yellow-fade {
  0% {
    background: $color_brand_sunny_yellow;
  }
  100% {
    background: none;
  }
}

@keyframes yellow-fade {
  0% {
    background: $color_brand_sunny_yellow;
  }
  100% {
    background: none;
  }
}

.highlight {
  -webkit-animation: yellow-fade 15s ease-in 1;
  animation: yellow-fade 15s ease-in 1;
}

.current {
  background-color: #007bff !important;
  color: #fff !important;
}

.hidden {
  display: none !important;
}

table.sticky-thead {
  thead th {
    background-color: #fff;
    position: sticky;
    top: 0;
  }
}
