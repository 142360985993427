@import "../../assets/css/variables";

.react-select {
  .react-select__control {
    border-radius: 0;
    border-color: #9e9e9e;
    background-color: #fbfcfc;
    box-shadow: none;
    min-height: 36px;
  }
  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .react-select__indicator {
    padding: 7px;
    color: #484848;
  }
  .react-select__menu {
    z-index: 9;
  }
}
