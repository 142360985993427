// Default
.rui-widget {
    position: relative;
    padding: 0 30px 20px;
    border: 1px solid $color_separator;
    border-radius: 0;

    .rui-widget-head {
        display: flex;
        align-items: center;
        padding: 20px 30px 20px;
        margin: 0 -30px 20px;
        background: rgba(245, 245, 245, 0.5);
        border-bottom: 1px solid $color_separator;

        .rui-widget-icon {
            margin-top: -14px;
            margin-bottom: -14px;
            margin-left: auto;
        }
        .rui-widget-title {
            margin-bottom: -8px;
        }
    }
    .rui-widget-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        &:not(:last-child) {
            padding-bottom: 25px;
        }

        > .rui-icon {
            margin-right: 10px;
            stroke: $color_grey_2;
        }
    }

    // List
    .rui-widget-list {
        margin-right: -30px;
        margin-bottom: -20px;
        margin-left: -30px;

        li {
            padding: 0;
        }
        .media-filled .media-link {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    .rui-widget-head ~ .rui-widget-content .rui-widget-list {
        margin-top: -20px;
    }

    // List Task
    .rui-widget-task-list > li {
        padding: 20px 30px;

        > .rui-task {
            padding: 18px 30px 18px;
            margin: -20px -30px;
        }
    }

    // List Country
    .rui-widget-country-list {
        max-height: 389px;
        overflow: auto;
    }

    // Chart
    &.rui-widget-chart {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 200px;
        padding: 30px;

        .rui-widget-chart-info {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .rui-widget-title {
            margin: 0 0 4px;
        }
        .rui-widget-subtitle {
            margin: 0;
            color: $color_grey_6;
        }
    }

    // Total
    &.rui-widget-total {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 240px;

        .rui-widget-chart-info {
            position: static;
            height: 100%;
        }
        .rui-widget-total-chart {
            margin: 0 -30px -30px;

            > .rui-chartjs:not([height]) {
                height: 80px;
            }
        }
    }

    // Server
    &.rui-widget-server {
        .rui-widget-content {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
        .rui-widget-server-chart {
            margin: 0 -30px -25px;

            > .rui-chartjs:not([height]) {
                height: 80px;
            }
        }
    }
}
